import React, { useEffect, useState } from 'react'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import Logo_v1 from '../../assets/svgComponents/kessel_logo_v1'
import { useLocation, useNavigate } from 'react-router-dom'


function Navigation() {
    const location = useLocation()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const { scroll } = useLocomotiveScroll()
    console.log("path",location.pathname)
    const handleScrollTo = (destination) =>{
        setIsOpen(false)
        console.log(destination)
        if(location.pathname === "/"){
            if (destination === "#the-top") {
                scroll && scroll.scrollTo(0, {
                    duration: 1000, // optional duration of the scroll animation
                    easing: [0.25, 0.46, 0.45, 0.94], // optional easing function
                  });
            }
            else{
                scroll && scroll.scrollTo(destination, {
                    duration: 1000, // optional duration of the scroll animation
                    easing: [0.25, 0.46, 0.45, 0.94], // optional easing function
                  });
            }  
        }
        else{
            if (destination === "#the-top") {
                navigate(`/`);
            }
            else{
                navigate(`/${destination}`);
            }
        }
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 991) {
                setIsOpen(false);
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <nav className="fs-styleguide-nav other">
            <div className="fs-styleguide-nav-background">
                <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="nav1_component w-nav">
                    <div className="page-padding">
                        <div className="nav1_container">
                            <a onClick={() => handleScrollTo("#the-top")} className="nav1_logo-link w-nav-brand"><div className='image-2'><Logo_v1 /></div></a>
                            <nav role="navigation" className={`nav1_menu w-nav-menu`} {...(isOpen ? { 'data-nav-menu-open': 'true' } : {})}>
                                <a onClick={() => handleScrollTo("#services")} className={`nav1_link ${isOpen?'mobile':''}`}>Services</a>
                                <a onClick={() => handleScrollTo("#about-us")}  className={`nav1_link ${isOpen?'mobile':''}`}>About us</a>
                                <a onClick={() => handleScrollTo("#our-projects")}  className={`nav1_link ${isOpen?'mobile':''}`}>Projects</a>
                                <div className={`nav1_button-wrapper ${isOpen?'mobile':''}`}>
                                    {isOpen?
                                    <a onClick={() => handleScrollTo("#contact-us")} className="button w-button">Contact us</a>
                                    :
                                    <a onClick={() => handleScrollTo("#contact-us")}  className="button contact w-inline-block">
                                        <div>Contact us</div>
                                    </a>
                                    }
                                </div>
                            </nav>
                            <div className="menu-button w-nav-button" onClick={()=> setIsOpen(!isOpen)}>
                                <div className="menu-icon w-embed">
                                    <svg width="1.25rem" height="1rem" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 7H1C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9H19C19.5523 9 20 8.55228 20 8C20 7.44772 19.5523 7 19 7Z" fill="currentColor"></path>
                                        <path d="M19 0H7C6.44772 0 6 0.447715 6 1C6 1.55228 6.44772 2 7 2H19C19.5523 2 20 1.55228 20 1C20 0.447715 19.5523 0 19 0Z" fill="currentColor"></path>
                                        <path d="M19 14H11C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14Z" fill="currentColor"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navigation