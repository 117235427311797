import React, { useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import Router from './pages/Router';
import LanguageWrapper from './components/LanguageWrapper';
import { configureStores } from './state/store';
import './assets/css/webflow.css'
import './assets/css/kssl.webflow.css'
import './assets/css/normalize.css'

const { store, persistor } = configureStores({});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <LanguageWrapper>
            <Router/>
        </LanguageWrapper>
    </Provider>
  );

