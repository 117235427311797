import { useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Logo_v1 from '../../assets/svgComponents/kessel_logo_v1'
import Navigation from '../../components/nav';
import Footer from '../../components/footer';


function ProjectPage(props) {
    const sliderRef = useRef(null);
    const { scroll } = useLocomotiveScroll()
    const handleScrollTo = (destination) =>{
        console.log(destination)
        scroll && scroll.scrollTo(destination, {
            duration: 1000, // optional duration of the scroll animation
            easing: [0.25, 0.46, 0.45, 0.94], // optional easing function
          });
    }

    const { id } = useParams();
    console.log(id)
    const data = props.content
    console.log("data",data)

    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        prevArrow: false,
        nextArrow: false,
        arrows: false,
        autoSlidesToShow: true,
        variableWidth: true,
        swipeToSlide: true,
        slidesToScroll: 1,
      };
  return (
    <>
        {/* <!DOCTYPE html><!--  This site was created in Webflow. https://www.webflow.com  -->
        <!--  Last Published: Tue Jun 13 2023 11:45:58 GMT+0000 (Coordinated Universal Time)  -->
        <html data-wf-page="64516d78bcac721f518797e0" data-wf-site="644ea99944c5360820a2262c" lang="en"> */}
        {/* <head>
        <meta charset="utf-8">
        <title>Kessel Construction | Real Estate and Commercial Construction Experts</title>
        <meta content="Discover the professionalism, expertise, and quality of Kessel Construction&#x27;s real estate and commercial construction projects. Trust our team of experts to bring your vision to life. Browse our portfolio and contact us today." name="description">
        <meta content="Kessel Construction | Real Estate and Commercial Construction Experts" property="og:title">
        <meta content="Discover the professionalism, expertise, and quality of Kessel Construction&#x27;s real estate and commercial construction projects. Trust our team of experts to bring your vision to life. Browse our portfolio and contact us today." property="og:description">
        <meta content="Kessel Construction | Real Estate and Commercial Construction Experts" property="twitter:title">
        <meta content="Discover the professionalism, expertise, and quality of Kessel Construction&#x27;s real estate and commercial construction projects. Trust our team of experts to bring your vision to life. Browse our portfolio and contact us today." property="twitter:description">
        <meta property="og:type" content="website">
        <meta content="summary_large_image" name="twitter:card">
        <meta content="width=device-width, initial-scale=1" name="viewport">
        <meta content="Webflow" name="generator">
        <link href="css/normalize.css" rel="stylesheet" type="text/css">
        <link href="css/webflow.css" rel="stylesheet" type="text/css">
        <link href="css/kssl.webflow.css" rel="stylesheet" type="text/css">
        <script type="text/javascript">!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);</script>
        <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon">
        <link href="images/webclip.png" rel="apple-touch-icon">
        <link href="https://ksslc.com/" rel="canonical">
        </head> */}


        <div className="locomotive-scroll">
            <div className="div-block-7"></div>
            <Navigation />
            <section id="top" data-scroll-section="1" className="fs-styleguide-home main">
                <div className="page-padding project-main">
                    <img src={data.mainImage?.data.url} loading="lazy" width="300" alt="" className="project-main-image"/>
                    <div className="div-block-8">
                        <div className="text-block-2">{data.name?.data}</div>
                    </div>
                </div>
                <div className="page-padding main-40 project">
                    <div className="w-layout-grid grid">
                        <div id="w-node-f8920c95-7122-aea2-2563-df91807dc6d2-1c6a971a" className="div-block-9 project">
                            <h4>Description</h4>
                            <p>{data.description?.data}</p>
                        </div>
                        <div id="w-node-_1aa16f71-2a72-c355-a65f-1ff539d5eacf-1c6a971a" className="div-block-12">
                            {/* <div id="w-node-d4836ad3-1f58-3d36-60c4-fb0c51ec14ed-1c6a971a" className="small-box-part">
                                <div className="data-title">Services Provided</div>
                                <div className="data-data">{data.servicesProvided}</div>
                            </div> */}
                            <div id="w-node-_4cfc4adc-b77c-9f91-57cc-f46a3193331a-1c6a971a" className="small-box-part">
                                <div className="data-title">Completion</div>
                                <div className="data-data">{data.completion?.data}</div>
                            </div>
                            <div id="w-node-_47d5e485-a911-f1d1-c15e-8b41c47550e4-1c6a971a" className="small-box-part">
                                <div className="data-title">Statistics</div>
                                <div className="data-data">{data.statistics?.data}</div>
                            </div>
                            <div id="w-node-ffc9b7c1-680a-28f4-9d19-5f0b49f005ab-1c6a971a" className="small-box-part">
                                <div className="data-title">Client</div>
                                <div className="data-data">{data.client?.data}</div>
                            </div>
                            <div id="w-node-e50d0b0c-9c73-17ed-7f50-0480e2cbd6d7-1c6a971a" className="small-box-part">
                                <div className="data-title">Location</div>
                                <div className="data-data">{data.location?.data}</div>
                            </div>
                            {/* <div id="w-node-d138576c-3773-a884-1ffd-bca579a048b6-1c6a971a" className="small-box-part">
                                <div className="data-title">Enhancement</div>
                                <div className="data-data">{data.enhancement}</div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <header id="services" data-scroll-section="1" className="section-layout1 brick gallery">
                <div className="wrapper-2 w-dyn-list">
                    {data.gallery === [] ? (
                        <div className="w-dyn-empty">
                        <div>No items found.</div>
                        </div>
                    ) : (
                         
                        <Slider {...settings} className="w-dyn-items" id="slider-id" ref={sliderRef}>
                            {data.gallery?.data?.map((uri, index) => (
                            <div role="listitem" key={index} className="item w-dyn-item">
                                <img src={uri.url} loading="lazy" alt="" className="image-13" />
                                <div className="image-13">
                                <div id="watermark" className="watermark">
                                    <Logo_v1 />
                                </div>
                                </div>
                            </div>
                            ))}
                        </Slider>
                    )}
                </div>
                <div className="slider-button-wrap-2">
                    <h1 className="heading-6"></h1>
                    <div className="arrow-wrapper-2">
                    <a onClick={() => sliderRef.current.slickPrev()} className="slider-button slider-prev w-inline-block">
                        <div className="rl_gallery21_arrow-icon w-embed"><svg width=" 100%" height=" 100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.81066 12.7499L12.5303 19.4696L11.4697 20.5303L2.93934 11.9999L11.4697 3.46961L12.5303 4.53027L5.81066 11.2499L20 11.2499L20 12.7499L5.81066 12.7499Z" fill="currentColor"></path>
                        </svg></div>
                    </a>
                    <a onClick={() => sliderRef.current.slickNext()} className="slider-button slider-next w-inline-block">
                        <div className="rl_gallery21_arrow-icon w-embed"><svg width=" 100%" height=" 100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.1893 11.2501L11.4697 4.53039L12.5303 3.46973L21.0607 12.0001L12.5303 20.5304L11.4697 19.4697L18.1893 12.7501H4V11.2501H18.1893Z" fill="currentColor"></path>
                        </svg></div>
                    </a>
                    </div>
                </div>
            </header>
            <Footer />
        </div>

    </>
  );
}

export default ProjectPage;




