import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo_v1_white from '../../assets/svgComponents/kessel_logo_v1_white'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

function Footer(projects) {

    const location = useLocation()
    const navigate = useNavigate()
    const { scroll } = useLocomotiveScroll()

    const handleScrollTo = (destination) =>{
        if(location.pathname === "/"){
            if (destination === "#the-top") {
                scroll && scroll.scrollTo(0, {
                    duration: 1000, // optional duration of the scroll animation
                    easing: [0.25, 0.46, 0.45, 0.94], // optional easing function
                  });
            }
            else{
                scroll && scroll.scrollTo(destination, {
                    duration: 1000, // optional duration of the scroll animation
                    easing: [0.25, 0.46, 0.45, 0.94], // optional easing function
                  });
            }  
        }
        else{
            if (destination === "#the-top") {
                navigate(`/`);
            }
            else{
                navigate(`/${destination}`);
            }
        }
    }

    return (
        <footer data-scroll-section-inview="true" data-scroll-section="1" className="footer2_component">
            <div data-scroll-speed="-8" data-scroll-position="bottom" data-scroll="1" className="page-padding brick">
                <div className="container-large footermoove luxy-el">
                    <div className="padding-vertical padding-large footer">
                        <div className="padding-bottom padding-large">
                            <div className="footer2_grid">
                                <div id="w-node-f8920c95-7122-aea2-2563-df91807dc764-1c6a971a" className="footer2_column">
                                    <div className="footer2_title text-color-white">Links</div>
                                    <a onClick={() => handleScrollTo("#services")} className="footer2_link text-color-white mobile">Services</a>
                                    <a onClick={() => handleScrollTo("#about-us")} className="footer2_link text-color-white mobile">About us</a>
                                    <a onClick={() => handleScrollTo("#our-projects")} className="footer2_link text-color-white mobile">Projects</a>
                                    <a onClick={() => handleScrollTo("#contact-us")} className="footer2_link text-color-white mobile">Contact us</a>
                                    <a onClick={() => handleScrollTo("#services")} className="footer2_link text-color-white services">Services</a>
                                    <a onClick={() => handleScrollTo("#about-us")} className="footer2_link text-color-white about">About us</a>
                                    <a onClick={() => handleScrollTo("#our-projects")} className="footer2_link text-color-white project">Projects</a>
                                    <a onClick={() => handleScrollTo("#contact-us")} className="footer2_link text-color-white contact">Contact us</a>
                                </div>
                                <div id="w-node-f8920c95-7122-aea2-2563-df91807dc777-1c6a971a" className="footer2_column">
                                    <div className="footer2_title text-color-white">Office</div>
                                    <a href="mailto:info@ksslc.com?subject=Inquiry" className="footer2_link text-color-white">info@ksslc.com</a>
                                    <a href="#" className="footer2_link text-color-white">Suite 21 East London Offices,<br/>80 - 84 St Mary Road, Walthamstow,<br/>London, England, E19 9RE</a>
                                </div>
                                <div id="w-node-f8920c95-7122-aea2-2563-df91807dc782-1c6a971a" className="footer2_info">
                                    <a onClick={() => handleScrollTo("#the-top")} className="footer2_logo-link w-inline-block"><div className="image-3"><Logo_v1_white /></div></a>
                                    <a onClick={() => handleScrollTo("#the-top")} className="footer2_logo-link mobile w-inline-block"><div className="image-3"><Logo_v1_white /></div></a>
                                    <div className="margin-top margin-medium">
                                        <div className="footer2_text text-color-white">At Kessel Construction, we are committed to delivering top-quality real estate and commercial construction projects with excellence and precision. Our experienced team of professionals uses the latest technology and best practices to ensure every project is a success.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line-divider"></div>
                    <div className="padding-vertical padding-large new-bottom">
                        <div className="footer2_bottom">
                            <div className="footer2_bottom-text text-color-white">© 2022 KESSEL CONSTRUCTION (UK) LIMITED </div>
                            <div id="w-node-f8920c95-7122-aea2-2563-df91807dc78f-1c6a971a" className="footer2_social-wrapper">
                                <a aria-label="Twitter link" href="https://twitter.com/" target="_blank" className="social-link w-inline-block">
                                <div className="social-icon w-embed"><svg aria-hidden="true" width="1.0625rem" height="0.875rem" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.25447 3.72422L8.29132 4.32976L7.67715 4.25561C5.44156 3.97138 3.4885 3.00746 1.83023 1.38857L1.01953 0.5853L0.810707 1.17848C0.368503 2.50078 0.651022 3.89723 1.57228 4.83643C2.06362 5.35547 1.95307 5.42962 1.10551 5.12067C0.810707 5.0218 0.552755 4.94766 0.528188 4.98473C0.442204 5.07124 0.737006 6.19581 0.970392 6.64069C1.28976 7.25859 1.94078 7.86413 2.65322 8.22251L3.25511 8.50674L2.54267 8.5191C1.8548 8.5191 1.83023 8.53146 1.90393 8.79098C2.1496 9.59424 3.11999 10.4469 4.20094 10.8177L4.96251 11.0772L4.2992 11.4727C3.31653 12.0411 2.16189 12.3624 1.00724 12.3871C0.454487 12.3995 0 12.4489 0 12.486C0 12.6096 1.49858 13.3016 2.3707 13.5735C4.98708 14.3768 8.09479 14.0307 10.4286 12.659C12.0869 11.6827 13.7452 9.74254 14.519 7.86413C14.9367 6.86314 15.3543 5.03416 15.3543 4.15675C15.3543 3.58828 15.3911 3.51413 16.079 2.83445C16.4844 2.43899 16.8652 2.00646 16.9389 1.88288C17.0617 1.64808 17.0494 1.64808 16.423 1.85817C15.3789 2.22891 15.2315 2.17948 15.7474 1.62337C16.1282 1.22791 16.5826 0.511153 16.5826 0.301067C16.5826 0.263994 16.3984 0.325783 16.1896 0.437005C15.9685 0.560584 15.4771 0.745954 15.1086 0.857175L14.4453 1.06726L13.8434 0.659448C13.5118 0.437005 13.045 0.189846 12.7993 0.115698C12.1729 -0.0573131 11.2148 -0.0325972 10.6497 0.16513C9.11431 0.721238 8.14392 2.15476 8.25447 3.72422Z" fill="currentColor"></path>
                                    </svg></div>
                                </a>
                                <a aria-label="Linkedin link" href="https://www.linkedin.com/" target="_blank" className="social-link w-inline-block">
                                <div className="social-icon w-embed"><svg aria-hidden="true" width="1rem" height="1rem" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1 0C0.447715 0 0 0.447715 0 1V15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15V1C16 0.447715 15.5523 0 15 0H1ZM5.15243 4.22361C5.13712 3.52903 4.64043 3 3.83386 3C3.0273 3 2.5 3.52903 2.5 4.22361C2.5 4.90381 3.01172 5.44807 3.80326 5.44807H3.81833C4.64043 5.44807 5.15243 4.90381 5.15243 4.22361ZM4.99714 6.41501H2.63942V13.499H4.99714V6.41501ZM13.5 9.43723C13.5 7.26134 12.3369 6.24864 10.7854 6.24864C9.53358 6.24864 8.97313 6.93621 8.6602 7.41856V6.41517H6.3022C6.33327 7.0799 6.3022 13.4992 6.3022 13.4992H8.6602V9.54287C8.6602 9.33116 8.67551 9.11996 8.73785 8.96838C8.90825 8.54541 9.29622 8.1075 9.9476 8.1075C10.8011 8.1075 11.1423 8.75708 11.1423 9.70903V13.499H13.4999L13.5 9.43723Z" fill="currentColor"></path>
                                    </svg></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer