import React from "react";

function Logo_v1_white() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="181.516"
      height="117.849"
      version="1.1"
      viewBox="0 0 48.026 31.181"
    >
      <defs>
        <radialGradient
          id="radialGradient9025"
          cx="113.094"
          cy="57.53"
          r="10.555"
          fx="113.094"
          fy="57.53"
          gradientTransform="matrix(.01064 -1.3309 1.51927 .01214 -71.793 168.625)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient9701"
        ></radialGradient>
        <linearGradient id="linearGradient9701">
          <stop offset="0" stopColor="#f8f8f8" stopOpacity="1"></stop>
          <stop offset="0.894" stopColor="#fdfdfd" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#f4f4f4" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient7166"
          x1="97.044"
          x2="118.154"
          y1="60.466"
          y2="60.466"
          gradientTransform="translate(-96.28 -39.267)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient7164-9"
        ></linearGradient>
        <linearGradient id="linearGradient7164-9">
          <stop offset="0" stopColor="#e9e9e9" stopOpacity="1"></stop>
          <stop offset="0.097" stopColor="#fafafa" stopOpacity="0.988"></stop>
          <stop offset="1" stopColor="#f7f7f7" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g fillOpacity="1">
        <g
          fill="#fff"
          strokeWidth="0.565"
          fontFamily="Century Gothic"
          fontSize="7.98"
          letterSpacing="6.878"
          transform="matrix(.37468 0 0 .37468 -36.32 166.853)"
        >
          <path
            d="M102.706-366.468l-.426.327q-.353-.459-.85-.694-.492-.24-1.084-.24-.646 0-1.198.313-.551.309-.856.835-.301.522-.301 1.176 0 .988.676 1.65.68.661 1.712.661 1.136 0 1.9-.889l.427.323q-.404.515-1.01.798-.604.28-1.35.28-1.418 0-2.238-.945-.687-.798-.687-1.926 0-1.187.83-1.996.835-.812 2.088-.812.757 0 1.367.301.61.298 1 .838z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M112.88-367.607q1.227 0 2.054.82.827.82.827 2.017 0 1.187-.827 2.014-.823.827-2.01.827-1.202 0-2.029-.823t-.827-1.992q0-.78.375-1.444.379-.666 1.03-1.04.65-.379 1.407-.379zm.026.53q-.6 0-1.136.312-.537.312-.838.841-.301.53-.301 1.18 0 .963.665 1.628.669.662 1.61.662.628 0 1.161-.305.537-.305.834-.835.302-.529.302-1.176 0-.643-.302-1.161-.297-.522-.841-.834-.544-.313-1.154-.313z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M123.124-362.065v-5.406h.117l3.595 4.138v-4.138h.533v5.406h-.122l-3.565-4.087v4.087z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M134.731-363.072l.46-.275q.485.893 1.12.893.273 0 .511-.125.24-.129.364-.342.125-.213.125-.452 0-.272-.184-.533-.253-.36-.926-.867-.676-.511-.841-.74-.287-.381-.287-.826 0-.353.169-.643.17-.29.474-.456.309-.169.67-.169.381 0 .712.191.335.188.706.695l-.441.334q-.305-.404-.522-.533-.213-.128-.467-.128-.327 0-.537.198-.205.199-.205.489 0 .176.073.342.074.165.268.36.107.103.699.54.702.518.963.923.26.404.26.812 0 .588-.448 1.022-.444.433-1.084.433-.492 0-.893-.26-.4-.265-.739-.883z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M145.258-366.945v-.526h2.966v.526h-1.206v4.88h-.55v-4.88z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M155.586-367.471h1.077q.9 0 1.22.073.482.11.783.475.302.36.302.889 0 .441-.21.776-.206.334-.592.507-.385.169-1.065.172l1.944 2.514h-.67l-1.94-2.514h-.308v2.514h-.54zm.54.53v1.837l.93.007q.54 0 .798-.103.261-.103.404-.327.147-.228.147-.507 0-.272-.147-.493-.147-.224-.386-.32-.238-.095-.793-.095z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M166.407-367.471h.54v3.264q0 .58.023.724.04.32.187.536.147.213.452.36.305.144.614.144.268 0 .514-.114.247-.114.412-.316.165-.202.243-.489.055-.206.055-.845v-3.264h.54v3.264q0 .724-.143 1.172-.14.445-.566.776-.423.33-1.026.33-.654 0-1.12-.312-.467-.312-.625-.827-.1-.316-.1-1.14z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M182.634-366.468l-.426.327q-.353-.459-.849-.694-.492-.24-1.084-.24-.647 0-1.198.313-.552.309-.857.835-.3.522-.3 1.176 0 .988.675 1.65.68.661 1.713.661 1.136 0 1.9-.889l.426.323q-.404.515-1.01.798-.603.28-1.35.28-1.418 0-2.237-.945-.688-.798-.688-1.926 0-1.187.831-1.996.834-.812 2.088-.812.757 0 1.367.301.61.298 1 .838z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M189.997-366.945v-.526h2.966v.526h-1.206v4.88h-.551v-4.88z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M200.325-367.471h.54v5.406h-.54z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M211.04-367.607q1.227 0 2.054.82.827.82.827 2.017 0 1.187-.827 2.014-.823.827-2.01.827-1.202 0-2.03-.823-.826-.823-.826-1.992 0-.78.375-1.444.378-.666 1.029-1.04.65-.379 1.408-.379zm.025.53q-.599 0-1.135.312-.537.312-.838.841-.302.53-.302 1.18 0 .963.665 1.628.67.662 1.61.662.629 0 1.162-.305.536-.305.834-.835.301-.529.301-1.176 0-.643-.301-1.161-.298-.522-.842-.834-.544-.313-1.154-.313z"
            style={{ lineHeight: "1.25" }}
          ></path>
          <path
            d="M221.283-362.065v-5.406h.118l3.594 4.138v-4.138h.533v5.406h-.12l-3.566-4.087v4.087z"
            style={{ lineHeight: "1.25" }}
          ></path>
        </g>
        <g transform="translate(-.155 -.093)">
          <g>
            <g fill="#fff">
              <g
                strokeWidth="0.251"
                fontFamily="nobel book"
                fontSize="34.809"
                letterSpacing="1.852"
                transform="translate(-11.733 -16.794) scale(1.40579)"
              >
                <path
                  d="M31.362 19.455l-3.35-4.143 2.979-3.18h-.883l-2.999 3.23v-3.23h-.692v7.323h.692v-3.19l.431-.451 2.94 3.64z"
                  style={{ lineHeight: "0.95" }}
                ></path>
                <path
                  d="M36.745 19.455v-.642h-3.37v-2.768h2.979v-.642h-2.979v-2.628h3.18v-.642h-3.872v7.322z"
                  style={{ lineHeight: "0.95" }}
                ></path>
                <path
                  d="M42.62 17.62c0-1.244-.883-1.716-1.775-2.177-.923-.482-1.595-.773-1.595-1.645 0-.722.582-1.114 1.223-1.114.622 0 1.054.15 1.485.572l.431-.531c-.541-.502-1.163-.713-1.916-.713-1.123 0-1.955.733-1.955 1.816 0 1.244.962 1.715 1.905 2.197.753.39 1.475.782 1.475 1.634 0 .783-.642 1.244-1.435 1.244-.581 0-1.163-.23-1.584-.602l-.422.522a3.045 3.045 0 002.026.752c1.134 0 2.137-.762 2.137-1.956z"
                  style={{ lineHeight: "0.95" }}
                ></path>
                <path
                  d="M30.58 27.148c0-1.244-.883-1.715-1.776-2.176-.923-.482-1.595-.773-1.595-1.645 0-.723.582-1.114 1.224-1.114.622 0 1.053.15 1.484.572l.432-.532c-.542-.501-1.164-.712-1.916-.712-1.123 0-1.956.732-1.956 1.816 0 1.243.963 1.715 1.906 2.196.752.392 1.474.783 1.474 1.635 0 .783-.642 1.244-1.434 1.244-.582 0-1.164-.23-1.585-.602l-.421.522a3.045 3.045 0 002.026.752c1.133 0 2.136-.762 2.136-1.956z"
                  style={{ lineHeight: "0.95" }}
                ></path>
                <path
                  d="M36.645 28.984v-.642h-3.37v-2.769h2.979v-.642h-2.98v-2.627h3.18v-.642h-3.871v7.322z"
                  style={{ lineHeight: "0.95" }}
                ></path>
                <path
                  d="M42.62 28.984v-.642h-3.09v-6.68h-.691v7.322z"
                  style={{ lineHeight: "0.95" }}
                ></path>
              </g>
              <path
                strokeWidth="0"
                d="M.17.116v23.972h22.298V.116zm.594.52h21.11v22.986H.764z"
                paintOrder="markers stroke fill"
              ></path>
            </g>
            <path
              fill="#fff"
              stroke="none"
              strokeWidth="0"
              d="M.739.636V.66L22.002.636zm21.263.012l-4.958 4.11H.74V23.79h21.263z"
              paintOrder="markers stroke fill"
            ></path>
            <path
              fill="url(#radialGradient9025)"
              stroke="none"
              strokeWidth="0"
              d="M.739.666v.023l21.11-.023zm21.11.011l-4.923 4.081H.74v18.894l.013-4.846h16.062l5.034 4.846z"
              paintOrder="markers stroke fill"
            ></path>
            <path
              fill="#fff"
              stroke="none"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeOpacity="1"
              strokeWidth="0.046"
              d="M21.874 23.645l-5.034-4.87.112-14.046 4.922-4.07z"
            ></path>
            <path
              fill="url(#linearGradient7166)"
              stroke="#121112"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              strokeWidth="0"
              d="M16.84 18.776H.778l-.014 4.846h21.11z"
            ></path>
          </g>
          <g
            fill="#fff"
            strokeWidth="0.565"
            fontFamily="Century Gothic"
            fontSize="7.98"
            letterSpacing="6.878"
            transform="matrix(.37468 0 0 .37468 -36.346 166.883)"
          >
            <path
              d="M102.706-366.468l-.426.327q-.353-.459-.85-.694-.492-.24-1.084-.24-.646 0-1.198.313-.551.309-.856.835-.301.522-.301 1.176 0 .988.676 1.65.68.661 1.712.661 1.136 0 1.9-.889l.427.323q-.404.515-1.01.798-.604.28-1.35.28-1.418 0-2.238-.945-.687-.798-.687-1.926 0-1.187.83-1.996.835-.812 2.088-.812.757 0 1.367.301.61.298 1 .838z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M112.88-367.607q1.227 0 2.054.82.827.82.827 2.017 0 1.187-.827 2.014-.823.827-2.01.827-1.202 0-2.029-.823t-.827-1.992q0-.78.375-1.444.379-.666 1.03-1.04.65-.379 1.407-.379zm.026.53q-.6 0-1.136.312-.537.312-.838.841-.301.53-.301 1.18 0 .963.665 1.628.669.662 1.61.662.628 0 1.161-.305.537-.305.834-.835.302-.529.302-1.176 0-.643-.302-1.161-.297-.522-.841-.834-.544-.313-1.154-.313z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M123.124-362.065v-5.406h.117l3.595 4.138v-4.138h.533v5.406h-.122l-3.565-4.087v4.087z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M134.731-363.072l.46-.275q.485.893 1.12.893.273 0 .511-.125.24-.129.364-.342.125-.213.125-.452 0-.272-.184-.533-.253-.36-.926-.867-.676-.511-.841-.74-.287-.381-.287-.826 0-.353.169-.643.17-.29.474-.456.309-.169.67-.169.381 0 .712.191.335.188.706.695l-.441.334q-.305-.404-.522-.533-.213-.128-.467-.128-.327 0-.537.198-.205.199-.205.489 0 .176.073.342.074.165.268.36.107.103.699.54.702.518.963.923.26.404.26.812 0 .588-.448 1.022-.444.433-1.084.433-.492 0-.893-.26-.4-.265-.739-.883z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M145.258-366.945v-.526h2.966v.526h-1.206v4.88h-.55v-4.88z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M155.586-367.471h1.077q.9 0 1.22.073.482.11.783.475.302.36.302.889 0 .441-.21.776-.206.334-.592.507-.385.169-1.065.172l1.944 2.514h-.67l-1.94-2.514h-.308v2.514h-.54zm.54.53v1.837l.93.007q.54 0 .798-.103.261-.103.404-.327.147-.228.147-.507 0-.272-.147-.493-.147-.224-.386-.32-.238-.095-.793-.095z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M166.407-367.471h.54v3.264q0 .58.023.724.04.32.187.536.147.213.452.36.305.144.614.144.268 0 .514-.114.247-.114.412-.316.165-.202.243-.489.055-.206.055-.845v-3.264h.54v3.264q0 .724-.143 1.172-.14.445-.566.776-.423.33-1.026.33-.654 0-1.12-.312-.467-.312-.625-.827-.1-.316-.1-1.14z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M182.634-366.468l-.426.327q-.353-.459-.849-.694-.492-.24-1.084-.24-.647 0-1.198.313-.552.309-.857.835-.3.522-.3 1.176 0 .988.675 1.65.68.661 1.713.661 1.136 0 1.9-.889l.426.323q-.404.515-1.01.798-.603.28-1.35.28-1.418 0-2.237-.945-.688-.798-.688-1.926 0-1.187.831-1.996.834-.812 2.088-.812.757 0 1.367.301.61.298 1 .838z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M189.997-366.945v-.526h2.966v.526h-1.206v4.88h-.551v-4.88z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M200.325-367.471h.54v5.406h-.54z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M211.04-367.607q1.227 0 2.054.82.827.82.827 2.017 0 1.187-.827 2.014-.823.827-2.01.827-1.202 0-2.03-.823-.826-.823-.826-1.992 0-.78.375-1.444.378-.666 1.029-1.04.65-.379 1.408-.379zm.025.53q-.599 0-1.135.312-.537.312-.838.841-.302.53-.302 1.18 0 .963.665 1.628.67.662 1.61.662.629 0 1.162-.305.536-.305.834-.835.301-.529.301-1.176 0-.643-.301-1.161-.298-.522-.842-.834-.544-.313-1.154-.313z"
              style={{ lineHeight: "1.25" }}
            ></path>
            <path
              d="M221.283-362.065v-5.406h.118l3.594 4.138v-4.138h.533v5.406h-.12l-3.566-4.087v4.087z"
              style={{ lineHeight: "1.25" }}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Logo_v1_white;
