import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicData } from "../state/actions/dynamicData";
/* eslint-disable */
export const useDynamicData = async() => {
    const dispatch = useDispatch()
    const dynamicData = useSelector(state => state.dynamicData)
    console.log(dynamicData)
    useEffect(() => {
        
        // if(dynamicData.data != null){
        //     console.log("Data found",dynamicData);

        // }
        // else {
            fetch('https://us-central1-kessel-construction.cloudfunctions.net/getProjects',{
            method: 'GET',
            })
            .then(response => response.json())
            .then(data => {
                console.log("data",data)
                const transformedData = {};

                data.forEach(obj => {
                if (!transformedData[obj.collection]) {
                    transformedData[obj.collection] = [];
                }
                transformedData[obj.collection] = transformedData[obj.collection].concat(obj.items);
                });

                console.log("transformed",transformedData);
                
                dispatch(setDynamicData(transformedData))
            })

            .catch(error => console.error(error));
        // };
    }, []);
};