import React, { useRef } from 'react';
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import { useSelector } from 'react-redux';

import paths from './paths';
import Home from '../home';
import ProjectPage from '../project';
import Project from '../project';
import { useDynamicData } from '../../hooks/useDynamicData';
import '../../assets/css/locomotive-scroll.css';

const ScrollProviderWrapper = () => {
  const containerRef = useRef(null);
  const location = useLocation();
  useDynamicData()
  const data = useSelector((state) => state.dynamicData.data)
  const projects = data?.projects
  console.log(location)
  return (
    <LocomotiveScrollProvider
      options={{
        smooth: true,
        smartphone: {
          smooth: true
        },
        tablet: {
          smooth: true
        },
        smoothMobile: 1,
        multiplier: 1.0,
      }}
      location={location.pathname}
      onLocationChange={scroll => {
        if (location.hash) {
          console.log('Hash ===', location.hash);
          setTimeout(() => {
            scroll && scroll.scrollTo(location.hash);
            console.log('Scrolled to:', location.hash);
          }, 300); // Adjust the delay time as needed
        }
        else{
          scroll.scrollTo(0, { duration: 0, disableLerp: true })
        }
      }}
      containerRef={containerRef}
    >
      <main data-scroll-container ref={containerRef}>
        <Routes>
          {projects && projects.map((page, index) => (
            <Route key={index} path={`project/${page.slug?.data}`} element={<ProjectPage content={page}/>} exact />
          ))}
          <Route exact path={paths.ROOT} element={<Home />} />
          <Route path={paths.PROJECT} element={<Project />} />
        </Routes>
      </main>
    </LocomotiveScrollProvider>
  );
};

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <ScrollProviderWrapper />
    </BrowserRouter>
  );
};

export default RouterComponent;