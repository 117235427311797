import { createReducer } from 'redux-act';

import { DYNAMIC_SET_DATA } from '../../actions/dynamicData';

const initialState = {
  data: null
};

export const dynamicDataReducer = createReducer(
  {
    [DYNAMIC_SET_DATA]: (state, payload) => ({
      ...state,
      data: payload.data
    })
  },
  initialState
);
