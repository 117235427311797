import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import { Link, useLocation } from 'react-router-dom'
import Slider from "react-slick";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navigation from '../../components/nav';
import Footer from '../../components/footer';


function HomePage() {
    const sliderRef = useRef(null);
    const { scroll } = useLocomotiveScroll()
    const location = useLocation();
    const handleScrollTo = (destination) =>{
        console.log(destination)
        if (destination === "#the-top") {
            scroll && scroll.scrollTo(0, {
                duration: 1000, // optional duration of the scroll animation
                easing: [0.25, 0.46, 0.45, 0.94], // optional easing function
              });
        }
        else{
            scroll && scroll.scrollTo(destination, {
                duration: 1000, // optional duration of the scroll animation
                easing: [0.25, 0.46, 0.45, 0.94], // optional easing function
              });
        }
    }
    useEffect(() => {
      if(location.hash) {
        handleScrollTo(`${location.hash}`)
      }
    }, [])
    
    const projects = useSelector((state) => state.dynamicData.data)
    const data = projects?.projects
    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        prevArrow: false,
        nextArrow: false,
        arrows: false,
        autoSlidesToShow: true,
        variableWidth: true,
        swipeToSlide: true,
        slidesToScroll: 1,
    };


    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);


    const isValidPhoneNumber = (number) => {
        // Add your phone number validation logic here
        // For example, you can use regular expressions to validate
        return true; // Replace with your validation logic
    };

    const isValidEmail = (email) => {
        // Add your email validation logic here
        // For example, you can use regular expressions to validate
        return true; // Replace with your validation logic
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        setLoading(true)
        
        if (!name || !company || !phone || !email || !message) {
            setFormError('Please fill in all fields.');
            setLoading(false)
            return;
        }

        if (!isValidPhoneNumber(phone)) {
            setPhoneError('Please enter a valid phone number.');
            setLoading(false)
            return;
        } else {
        setPhoneError('');
        }

        if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email address.');
            setLoading(false)
            return;
        } else {
        setEmailError('');
        }

        // If all validations pass, you can proceed with form submission logic here
        // Reset any error messages if needed
        setFormError('');
        
        console.log("testing::::::::", JSON.stringify({ name, company, phone, email, message }))
        try {
            const response = await fetch('https://us-central1-kessel-construction.cloudfunctions.net/api/postSubmitForm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({ name, company, phone, email, message }),
            });
    
            if (response) {
                // Handle successful submission
                
                setResponse('success')
                setLoading(false)
                console.log('Form submitted successfully',response);
            } else {
                setResponse('Error submitting form')
                setLoading(false)
                // Handle submission error
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false)
        }
    };

    
    
  return (
    <>
        {/* <!DOCTYPE html><!--  This site was created in Webflow. https://www.webflow.com  -->
        <!--  Last Published: Tue Jun 13 2023 11:45:58 GMT+0000 (Coordinated Universal Time)  -->
        <html data-wf-page="64516d78bcac721f518797e0" data-wf-site="644ea99944c5360820a2262c" lang="en"> */}
        {/* <head>
        <meta charset="utf-8">
        <title>Kessel Construction | Real Estate and Commercial Construction Experts</title>
        <meta content="Discover the professionalism, expertise, and quality of Kessel Construction&#x27;s real estate and commercial construction projects. Trust our team of experts to bring your vision to life. Browse our portfolio and contact us today." name="description">
        <meta content="Kessel Construction | Real Estate and Commercial Construction Experts" property="og:title">
        <meta content="Discover the professionalism, expertise, and quality of Kessel Construction&#x27;s real estate and commercial construction projects. Trust our team of experts to bring your vision to life. Browse our portfolio and contact us today." property="og:description">
        <meta content="Kessel Construction | Real Estate and Commercial Construction Experts" property="twitter:title">
        <meta content="Discover the professionalism, expertise, and quality of Kessel Construction&#x27;s real estate and commercial construction projects. Trust our team of experts to bring your vision to life. Browse our portfolio and contact us today." property="twitter:description">
        <meta property="og:type" content="website">
        <meta content="summary_large_image" name="twitter:card">
        <meta content="width=device-width, initial-scale=1" name="viewport">
        <meta content="Webflow" name="generator">
        <link href="css/normalize.css" rel="stylesheet" type="text/css">
        <link href="css/webflow.css" rel="stylesheet" type="text/css">
        <link href="css/kssl.webflow.css" rel="stylesheet" type="text/css">
        <script type="text/javascript">!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);</script>
        <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon">
        <link href="images/webclip.png" rel="apple-touch-icon">
        <link href="https://ksslc.com/" rel="canonical">
        </head> */}

        {/* <nav className="fs-styleguide-nav">
            <div className="fs-styleguide-nav-fixed">
                <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="nav1_component w-nav">
                    <div className="page-padding">
                        <div className="nav1_container">
                            <a href="#" className="nav1_logo-link w-nav-brand"></a>
                            <nav role="navigation" className="nav1_menu w-nav-menu">
                                <a href="#services" className="nav1_link">Services</a>
                                <a href="#about-us" className="nav1_link">About us</a>
                                <a href="#our-projects" className="nav1_link">Projects</a>
                                <div className="nav1_button-wrapper">
                                    <a href="#contact" className="button w-button">Contact us</a>
                                </div>
                            </nav>
                            <div className="menu-button w-nav-button">
                                <div className="menu-icon w-embed">
                                    <svg width="1.25rem" height="1rem" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 7H1C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9H19C19.5523 9 20 8.55228 20 8C20 7.44772 19.5523 7 19 7Z" fill="currentColor"></path>
                                        <path d="M19 0H7C6.44772 0 6 0.447715 6 1C6 1.55228 6.44772 2 7 2H19C19.5523 2 20 1.55228 20 1C20 0.447715 19.5523 0 19 0Z" fill="currentColor"></path>
                                        <path d="M19 14H11C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14Z" fill="currentColor"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fs-styleguide-nav-background"></div>
        </nav> */}
        <div className="locomotive-scroll">
            <div className="div-block-7"></div>
            <Navigation />
            <section id="top" data-scroll-section="1" className="fs-styleguide-home main">
            <div className="page-padding main">
                <div className="div-block-8">
                <div className="text-block-2">Crafting exceptional spaces that inspire and empower individuals and businesses</div>
                </div>
                <div className="container-small">
                <div className="padding-vertical padding-huge"></div>
                </div>
            </div>
            <div className="page-padding main-40">
                <div className="w-layout-grid grid">
                <div className="div-block-9">
                    <h4>Services </h4>
                    <p>From project management and design to construction and planning. Our team has extensive experience in both real estate and commercial construction.</p>
                    <a onClick={() => handleScrollTo("#services")} className="navlink mobile w-inline-block">
                    <div className="navtext">Learn more</div>
                    </a>
                    <a onClick={() => handleScrollTo("#services")} className="navlink to--services w-inline-block">
                    <div className="navtext">Learn more</div>
                    </a>
                </div>
                <div className="div-block-9">
                    <h4>About us</h4>
                    <p>At Kessel Construction, we are committed to delivering top-quality real estate and commercial construction projects with excellence and precision.</p>
                    <a onClick={() => handleScrollTo("#about-us")} className="navlink to--about w-inline-block">
                    <div className="navtext">Learn more</div>
                    </a>
                    <a onClick={() => handleScrollTo("#about-us")}  className="navlink mobile w-inline-block">
                    <div className="navtext">Learn more</div>
                    </a>
                </div>
                <div 
                    className="div-block-9 image" 
                    style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, .27), rgba(0, 0, 0, .27)), url('${data && data[0]?.mainImage?.data.url}')`,
                        backgroundPosition: '0 0, 50% 0',
                        backgroundSize: 'auto, cover',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        display: 'flex',
                    }}
                >
                    <h4 className="sub-menu white">Projects</h4>
                    <a onClick={() => handleScrollTo("#our-projects")} className="navlink to--projects w-inline-block">
                    <div className="navtext">View more</div>
                    </a>
                    <a onClick={() => handleScrollTo("#our-projects")} className="navlink mobile w-inline-block">
                    <div className="navtext">View more</div>
                    </a>
                </div>
                <div className="div-block-9">
                    <h4>Contact</h4>
                    <p>Don&#x27;t hesitate to reach out to us via phone, email, or our online contact form. We look forward to hearing from you!</p>
                    <a onClick={() => handleScrollTo("#contact-us")} className="navlink to--contact w-inline-block">
                        <div className="navtext">Contact us</div>
                    </a>
                    <a onClick={() => handleScrollTo("#contact-us")} className="navlink mobile w-inline-block">
                    <div className="navtext">Contact us</div>
                    </a>
                </div>
                </div>
            </div>
            </section>
            <header id="services" data-scroll-section="1" className="section-layout1 brick">
            <div className="padding-vertical padding-large brick">
                <div className="w-layout-grid layout1_component brick">
                <div className="layout1_image-wrapper">
                    <div className="image-1 services">&#x27;</div>
                </div>
                <div id="w-node-_610038d2-cca1-843f-1cad-84b222e37526-518797e0" className="page-padding textContainer">
                    <div className="layout1_content">
                    <div className="heading-2">Services</div>
                    <div className="margin-top margin-small">
                        <div className="text-size-small">At Kessel Construction, we provide a wide range of high-end services to clients in the construction industry. Our team of experts has years of experience in providing bespoke design solutions, project management, refurbishments, new builds, and planning services. We take pride in delivering innovative and cost-effective solutions that exceed our clients&#x27; expectations. With our attention to detail and dedication to excellence, we aim to bring your vision to life and create a space that perfectly meets your needs.</div>
                        <a href="#" className="navlink inpage w-button">Learn more</a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </header>
            <header id="about-us" data-scroll-section="1" className="section-layout1 brick">
            <div className="padding-vertical padding-large brick reverse">
                <div className="w-layout-grid layout1_component brick reverse">
                <div id="w-node-_610038d2-cca1-843f-1cad-84b222e37533-518797e0" className="page-padding textContainer">
                    <div className="layout1_content">
                    <div className="heading-2">About us</div>
                    <div className="margin-top margin-small">
                        <div className="text-size-small">Kessel Construction is a leading construction company in London, UK, established in 2014. We pride ourselves on our commitment to delivering high-end construction solutions that are tailored to our clients&#x27; unique needs and preferences. Our team of experienced professionals brings a wealth of knowledge and expertise to every project, ensuring that we consistently exceed our clients&#x27; expectations. From design and planning to project management and construction, we take care of every aspect of the process, providing a seamless experience that delivers results. With our attention to detail and dedication to excellence, we aim to create a space that perfectly meets your needs and surpasses your expectations. Let us help you bring your vision to life and create a better future today.</div>
                        <a href="#" className="navlink inpage w-button">Learn more</a>
                    </div>
                    </div>
                </div>
                <div className="layout1_image-wrapper">
                    <div className="image-1 construction"></div>
                </div>
                </div>
            </div>
            </header>
            <header id="our-projects" data-scroll-section="1" className="section-layout1 brick">
                <div className="padding-vertical padding-large brick slider-wrapper">
                    <div className="home-slider-wrapper">
                        <Slider {...settings} className="slider w-slider" ref={sliderRef}>
                            {data && data?.length != 0 ?
                            data.map((item, index) => (
                                <div role="listitem" key={index} className="item2 w-dyn-item">
                                    <img src={item.mainImage?.data.url} loading="lazy" alt="" className="sliderImage" />
                                    <div className="sliderImage">
                                        <Link to={`project/${item.slug?.data}`} className="link-block w-inline-block">
                                            <div className="div-block-11">
                                                <div className="text-block-3">{item.name?.data}</div>
                                            </div>
                                            <div className="div-block-10"></div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                            :
                            <div className="item2 w-dyn-item">No projects added</div>
                            }
                        </Slider>
                        <div className="slider-button-wrap-2">
                        <div className="heading-2">Projects</div>
                            <div className="arrow-wrapper-2">
                                <a onClick={() => sliderRef.current.slickPrev()} className="slider-button slider-prev w-inline-block">
                                    <div className="rl_gallery21_arrow-icon w-embed"><svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.81066 12.7499L12.5303 19.4696L11.4697 20.5303L2.93934 11.9999L11.4697 3.46961L12.5303 4.53027L5.81066 11.2499L20 11.2499L20 12.7499L5.81066 12.7499Z" fill="currentColor"></path>
                                    </svg></div>
                                </a>
                                <a onClick={() => sliderRef.current.slickNext()} className="slider-button slider-next w-inline-block">
                                    <div className="rl_gallery21_arrow-icon w-embed"><svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M18.1893 11.2501L11.4697 4.53039L12.5303 3.46973L21.0607 12.0001L12.5303 20.5304L11.4697 19.4697L18.1893 12.7501H4V11.2501H18.1893Z" fill="currentColor"></path>
                                    </svg></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <header id="contact-us" data-scroll-section="1" className="section-layout1 brick">
                <div className="padding-vertical padding-large brick reverse">
                    <div className="w-layout-grid layout1_component brick reverse">
                        <div id="w-node-_610038d2-cca1-843f-1cad-84b222e37545-518797e0" className="page-padding contact">
                            <div className="layout1_content contact">
                                <div className="heading-2">Contact us</div>
                                <div className="margin-top margin-small">
                                    <div className="text-size-small">Discover how we can help you bring your project to life. </div>
                                    <div className="form-block w-form">
                                        {response == ''?
                                        <form id="email-form" name="email-form" data-name="Email Form" onSubmit={handleSubmit}>
                                            {formError && <p style={{ color: 'red' }}>{formError}</p>}
                                            <label htmlFor="name">Name*</label>
                                            <input
                                                type="text"
                                                className="w-input"
                                                maxLength="256"
                                                name="name"
                                                data-name="Name"
                                                placeholder=""
                                                id="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            <label htmlFor="name">Company*</label>
                                            <input
                                                type="text"
                                                className="w-input"
                                                maxLength="256"
                                                name="name"
                                                data-name="Company"
                                                placeholder=""
                                                id="company"
                                                value={company}
                                                onChange={(e) => setCompany(e.target.value)}
                                            />
                                            {/* ... Other input fields ... */}
                                            <label htmlFor="name-3">Phone*</label>
                                            <input
                                                type="text"
                                                className="w-input"
                                                maxLength="256"
                                                name="name-3"
                                                data-name="Name 3"
                                                placeholder=""
                                                id="name-3"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                            {phoneError && <p style={{ color: 'red' }}>{phoneError}</p>}
                                            {/* ... Other input fields ... */}
                                            <label htmlFor="email">Email Address*</label>
                                            <input
                                                type="email"
                                                className="w-input"
                                                maxLength="256"
                                                name="email"
                                                data-name="Email"
                                                placeholder=""
                                                id="email"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                                            {/* ... Other input fields ... */}
                                            <label htmlFor="name">Message*</label>
                                            <input
                                                type="text"
                                                className="w-input"
                                                maxLength="256"
                                                name="name"
                                                data-name="Message"
                                                placeholder=""
                                                id="message"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            />
                                            <button disabled={loading} type="submit" className="navlink submit w-button">
                                                {loading && <Spinner />}
                                                <div style={{paddingLeft: 15}}>{loading? "Submitting":"Submit"}</div>
                                            </button>
                                        </form>
                                        :
                                        response == 'success'?
                                        <div className="w-form-done">
                                            <div>Thank you! Your submission has been received!</div>
                                        </div>
                                        :
                                        <div className="w-form-fail">
                                            <div>Oops! Something went wrong while submitting the form.</div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="layout1_image-wrapper">
                            <div className="image-1 contact"></div>
                        </div>
                    </div>
                </div>
            </header>
            <Footer />
        </div>
    </>
  );
}

export default HomePage;




